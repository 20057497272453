<script setup lang="ts">
const { banner, showPromotionPopup, navigatePromo, closePromo } = usePromotionBanner();
</script>

<template>
  <ApzModal
    :model-value="showPromotionPopup"
    :dismissable="false"
    class="!bg-transparent !shadow-none !p-0"
  >
    <div class="flex flex-col items-center justify-center gap-y-30">
      <NuxtImg
        :src="banner!.img"
        :alt="banner?.alt ?? ''"
        width="470"
        height="590"
        sizes="lg:940px"
        format="webp"
        fit="scale-down"
        class="aspect-[470/590] w-[470px]"
        @click="navigatePromo"
      />

      <ApzIcon
        icon="times-circle-alt"
        class="text-[40px] text-white"
        @click="closePromo"
      />
    </div>
  </ApzModal>
</template>
